import styles from "../modules/app.module.css";
import {Link} from "react-router-dom";
import {WEBSITE_NAME} from "../util/util";

export default function NotFound() {
  return (
      <main className={styles.main}>
          <div className={styles.nav}>
              <div className={styles.nav_left}>
                  <Link to="/">{WEBSITE_NAME}</Link>
              </div>
          </div>
          <div className={styles.content}>
              <div>
                  <h2>Error: 404</h2>
                  <p>Page not found.</p>
              </div>
          </div>
      </main>
  );
}