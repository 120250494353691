import { useClickAway } from "react-use";
import { useRef } from "react";
import { useState } from "react";
import { Squash as Hamburger } from "hamburger-react";
import { AnimatePresence, motion } from "framer-motion";
import {WEBSITE_NAME} from "../util/util";
import {useUser} from "../auth/auth_user";
import styles from '../modules/mobile.module.css'
import {Link} from "react-router-dom";

export const routes = [
  {
    title: "Home",
    href: "/#"
  },
  {
    title: "Groups",
    href: "/#/group"
  },
  {
    title: "User",
    href: "/#/user"
  },
  {
    title: "Login",
    href: "/#/login"
  },
  {
    title: "Register",
    href: "/#/register"
  },
  {
    title: "Log Off",
    href: "/#"
  },
];

export const NavMobile = () => {
  const {signedUser, setSignedUser} = useUser()
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => setOpen(false));

  return (
      <>
        <div ref={ref} className={styles.mobile_nav}>
          <Link to="/" className={styles.nav_logo}>{WEBSITE_NAME}</Link>
          <Hamburger toggled={isOpen} size={20} toggle={setOpen}/>
        </div>
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 0.2}}
                    className={styles.nav_list_container}
                >
                  <ul>
                    {routes.map((route, idx) => {
                      if (signedUser === "" && route.title === "Log Off") {
                        return <></>
                      } else if (signedUser !== "" && (route.title === "Login" || route.title === "Register")) {
                        return <></>
                      }

                      if (route.title === "Log Off") {
                        return (
                            <motion.li
                                initial={{scale: 0, opacity: 0}}
                                animate={{scale: 1, opacity: 1}}
                                transition={{
                                  type: "spring",
                                  stiffness: 260,
                                  damping: 20,
                                  delay: 0.1 + idx / 10,
                                }}
                                key={route.title}
                            >
                              <a
                                  onClick={() => setSignedUser("")}
                                  href={route.href}
                              >
                                <span>{route.title}</span>
                              </a>
                            </motion.li>
                        )
                      }

                      return (
                          <motion.li
                              initial={{scale: 0, opacity: 0}}
                              animate={{scale: 1, opacity: 1}}
                              transition={{
                                type: "spring",
                                stiffness: 260,
                                damping: 20,
                                delay: 0.1 + idx / 10,
                              }}
                              key={route.title}
                              className="w-full p-[0.08rem] rounded-xl bg-gradient-to-tr from-neutral-800 via-neutral-950 to-neutral-700"
                          >
                            <a
                                onClick={() => setOpen((prev) => !prev)}
                                className={
                                  "flex items-center justify-between w-full p-5 rounded-xl bg-neutral-950"
                                }
                                href={route.href}
                            >
                              <span className="flex gap-1 text-lg">{route.title}</span>
                            </a>
                          </motion.li>
                      );
                    })}
                  </ul>
                </motion.div>
            )}
          </AnimatePresence>
      </>
  );
};