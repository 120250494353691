import styles from "../modules/user.module.css";
import {Link, Navigate, useParams} from "react-router-dom";
import {deleteCookie, useUser} from "../auth/auth_user";
import {useEffect, useState} from "react";
import {useMobile, WEBSITE_NAME} from "../util/util";
import {NavMobile} from "../mobile_pages/mobile_nav";
import m_styles from "../modules/mobile.module.css";
import {EditMember} from "../components/overlay";
import {GuestGroups} from "../util/guest";

export const UserNav = () => {
    const {signedUser} = useUser()
    if (signedUser === "") {
        return <GuestGroups/>
    } else {
        return <Navigate to={`/user/${signedUser}`}/>
    }
}

export default function User() {
    const match = useParams()
    const mobile = useMobile()
    const {signedUser, setSignedUser} = useUser()
    const [user_data, setData] = useState()
    const [point, setPoint] = useState(0)
    const [edit, setEdit] = useState(false)

    function logOut() {
        setSignedUser("");
        deleteCookie('User');
    }

    function count_points(data) {
        let points = 0
        for (const key in data.groups.M) {
            points = points + Number(data.groups.M[key].M.points.N)
        }
        return points
    }

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': 'http://localhost:3000'
            },
            credentials: "include"
        };

        fetch(`https://znmfo6873b.execute-api.eu-west-3.amazonaws.com/default/data?type=getUser&id=${match.username}`, requestOptions)
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        setData(data)
                        setPoint(count_points(data))
                        })
                } else {
                    throw new Error('User does not exist or smt?');
                }
            }).catch(rejected => {
                setData(null)
                console.log(rejected);
            });
    }, []);

    if (signedUser === "" && !match.username) {
        return <Navigate to='/login'/>
    }

    if (!user_data) {
        return (
          <main className={styles.main}>
              <div className={styles.nav}>
                  <div className={styles.nav_left}>
                      <Link to="/">{WEBSITE_NAME}</Link>
                  </div>
              </div>
              <div className={styles.content}>
                  <div>
                      <h2>Loading</h2>
                      <p>Loading user: {match.username}</p>
                  </div>
              </div>
          </main>
      );
    }

    return (
        <>
            {edit
                ? <EditMember close_overlay={setEdit}/>
                : <></>
            }
        <main className={mobile ? m_styles.main : styles.main}>
            {mobile
                ? <NavMobile/>
                : <div className={styles.nav}>
                    <div className={styles.nav_left}>
                        <Link to="/">{WEBSITE_NAME}</Link>
                        <Link to="/group">Groups</Link>
                        <Link to={`/user/${signedUser}`}>User</Link>
                    </div>
                    {signedUser
                        ? <div className={styles.nav_right} onClick={logOut}>Log Out</div>
                        : <div className={styles.nav_right}><Link to="/login">Login</Link>
                            <div>/</div>
                            <Link to="/register">Register</Link></div>
                    }
                </div>
            }
            <div className={styles.content_row}>
                <div className={styles.content}>
                    <h1 className={styles.name}>{user_data.first_name.S} {user_data.last_name.S}</h1>
                    <div>Username: {user_data.username.S}</div>
                    <div>Friendly Member since {user_data.creation_date.S}</div>
                    <div className={styles.stats}>
                        <p>{Object.keys(user_data.bets.M).length} Bets</p>
                        <p>{point} {mobile ? "Tot. pts" : "Total Points"}</p>
                    </div>
                    <div className={styles.stats}>
                        <p>{Object.keys(user_data.groups.M).length} Groups</p>
                        <div onClick={() => setEdit(true)}>Edit</div>
                    </div>
                </div>
            </div>
        </main>
        </>
    );
}