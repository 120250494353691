import {HashRouter, Routes, Route} from "react-router-dom";
import Home from "./pages/home";
import Group, {SpecificGroup} from "./pages/group";
import Login from "./pages/login";
import Register from "./pages/register";
import NotFound from "./pages/404";
import User, {UserNav} from "./pages/user";
import {UserAuthProvider} from "./auth/auth_user";
import Policy from "./pages/Policy";
import Invite from "./util/invite";
import {MobileProvider} from "./util/util";


export default function App() {
    return (
        <UserAuthProvider>
            <MobileProvider>
                <HashRouter>
                    <Routes>
                        <Route path='/' element={<Home/>}/>
                        <Route path='/group' element={<Group/>}/>
                        <Route path='/group/:group' action={({ params }) => {}} element={<SpecificGroup/>}/>
                        <Route path='/user' element={<UserNav/>}/>
                        <Route path='/user/:username' action={({ params }) => {}} element={<User/>}/>
                        <Route path='/login' element={<Login/>}/>
                        <Route path='/register' element={<Register/>}/>
                        <Route exact path="/policy" element={<Policy/>} />
                        <Route path="/invite/:validate/:group_id" action={({params}) => {}} element={<Invite/>}/>
                        <Route path='/*' element={<NotFound/>}/>
                    </Routes>
                </HashRouter>
            </MobileProvider>
        </UserAuthProvider>
    );
}
