import React, {useState} from "react";
import {sendLogin, sendRegister, setCookie, useUser} from "../auth/auth_user";
import styles from "../modules/app.module.css";
import login_styles from "../modules/login.module.css";
import {Link, useNavigate} from "react-router-dom";
import {useMobile, WEBSITE_NAME} from "../util/util";
import m_styles from "../modules/mobile.module.css";
import {NavMobile} from "../mobile_pages/mobile_nav";

export default function Register() {
    const mobile = useMobile()

    return (
        <main className={mobile ? m_styles.main : styles.main}>
            {mobile
                ? <NavMobile/>
                : <div className={styles.nav}>
                    <div className={styles.nav_left}>
                        <Link to="/">{WEBSITE_NAME}</Link>
                        <Link to="/group">Groups</Link>
                        <Link to={`/user`}>User</Link>
                    </div>
                    <Link to="/login">Login</Link>
            </div>
            }
            <RegisterForm/>
        </main>
    );
}

function RegisterForm() {
    const mobile = useMobile()
    const {setSignedUser} = useUser()
    const navigate = useNavigate()

    const [fail, setFail] = useState(false)
    const [error, setError] = useState("")
    const [isAuthenticating, setIsAuthenticating] = useState(false)

    const [formData, setFormData] = useState({
        type: "makeUser",
        first_name: "",
        last_name: "",
        username: "",
        password: ""
    })

    const handleChange = (e) => {
        const { name, value, type, checked, options } = e.target;
        if (type === "checkbox") {
            const selectedOptions = Array.from(options).filter((option) => option.selected).map((option) => option.value);
            setFormData({ ...formData, [name]: selectedOptions });
        } else if (type === "radio" && checked) {
            setFormData({ ...formData, [name]: value });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    return (
        <>{isAuthenticating
            ? <div className={login_styles.loading}>
                <div className={login_styles.form}>
                    <div className={login_styles.label}>
                        Loading...
                    </div>
                </div>
            </div>
            : <form className={mobile ? m_styles.login_form : login_styles.form} onSubmit={() => {
                sendRegister(
                    navigate,
                    formData,
                    setError,
                    setFail,
                    setIsAuthenticating
                ).then(data => {
                    sendLogin(
                        navigate,
                        formData.username,
                        formData.password,
                        setError,
                        setFail,
                        setIsAuthenticating
                    ).then(data => setSignedUser(formData.username))
                })
            }}>
                <label htmlFor="first_name" className={login_styles.label}>
                    First Name:
                </label>
                <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    className={login_styles.input}/>
                <label htmlFor="last_name" className={login_styles.label}>
                    Last Name:
                </label>
                <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    className={login_styles.input}/>
                <label htmlFor="username" className={login_styles.label}>
                    Username:
                </label>
                <input
                    type="text"
                    id="username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    className={login_styles.input}/>
                <label htmlFor="password" className={login_styles.label}>
                    Password:
                </label>
                <input
                    type="password"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    className={login_styles.input}/>
                {fail
                    ? <div className={login_styles.error_message}>
                        {error !== ""
                            ? error
                            : "Register failed, please try again"
                        }
                    </div>
                    : <></>
                }
                <button type="submit" className={login_styles.button}>
                    Submit
                </button>
            </form>
        }
        </>
    )
}