import styles from "../modules/app.module.css";
import m_styles from "../modules/mobile.module.css";
import {Link} from "react-router-dom";
import {deleteCookie, useUser} from "../auth/auth_user";
import {useMobile, WEBSITE_NAME} from "../util/util";
import image from "../util/young-people.jpg";
import {NavMobile} from "../mobile_pages/mobile_nav";
import {useState} from "react";

export default function Home() {
    const mobile = useMobile()
    const {signedUser, setSignedUser} = useUser()

    function logOut() {
        setSignedUser("");
        deleteCookie('User');
    }

    return (
        <main className={mobile ? m_styles.main : styles.main}>
            {mobile
                ? <NavMobile/>
                : <div className={styles.nav}>
                    <div className={styles.nav_left}>
                        <Link to="/">{WEBSITE_NAME}</Link>
                        <Link to="/group">Groups</Link>
                        <Link to={`/user/${signedUser}`}>User</Link>
                    </div>
                    {signedUser
                        ? <div className={styles.nav_right} onClick={logOut}>Log Out</div>
                        : <div className={styles.nav_right}><Link to="/login">Login</Link>
                            <div>/</div>
                            <Link to="/register">Register</Link></div>
                    }
                </div>
            }
            {mobile
                ? <div className={m_styles.content}>
                    <img src={image} className={m_styles.picture} alt="friends hugging and looking over a field"/>
                    <div>
                        <h2>Start tracking your life with some friendly bets</h2>
                        <p>Build bets and see which one of your friends can better predict the future.</p>
                        <Link to="/user">Start Now</Link>
                    </div>
                </div>
                : <div className={styles.content}>
                    <div>
                        <h2>Start tracking your life with some friendly bets</h2>
                        <p>Build bets and see which one of your friends can better predict the future.</p>
                        <Link to="/user">Start Now</Link>
                    </div>
                    <img src={image} className={styles.picture} alt="friends hugging and looking over a field"/>
                </div>
            }
            <div className={mobile ? m_styles.content_two : styles.content_two}>
                <div>
                    <h1>How does it work?</h1>
                    <h2>1. Create an account</h2>
                    <p>(No email needed. Name and last name are fully optional)</p>
                    <h2>2. Create a New Group from the Group tab</h2>
                    <h2>3. Invite your friends to the platform</h2>
                    <p>(You can add your friends manually or send them an invite link)</p>
                    <h2>6. Create a New Bet</h2>
                    <h2>7. ATTEMPT TO PREDICT THE FUTURE AND HAVE FUN!!!</h2>
                </div>
            </div>
            <div className={mobile ? m_styles.content_two : styles.content_two}>
                <div>
                    <h1>Some Bet Examples:</h1>
                    <h2>1. Will our dear friend Bob get a girlfriend by the end of the month?</h2>
                    <h2>2. Will Eve and Anne FINALLY get together this week in Barcelona?</h2>
                    <h2>3. Someone will add to the world population by the end of the year</h2>
                    <h2>4. Cody will not score a single point on this app...</h2>
                </div>
            </div>
            <div className={mobile ? m_styles.content_two : styles.content_two}>
                <div>
                    <h1>FAQ</h1>
                    <h2>1. Why did you make this?</h2>
                    <p>Honestly, I decided to make it simply cuz my best friend told me I shouldn't ¯\_(ツ)_/¯</p>
                    <h2>2. What if I wanna make money with these bets?</h2>
                    <p>It's a free country, do as you wish. Just do not tell me for legal purposes</p>
                    <h2>3. Can I buy you a coffee?</h2>
                    <p>If you wish to donate, I have a minimum donation of 1 Million dollars.</p>
                    <h2>4. I found a bug, how do I send it over?</h2>
                    <p>You don't, and hope that I catch on and fix it.</p>
                    <h2>5. How can I delete a Group</h2>
                    <p>Like friends, they cannot be deleted (legally at least)</p>
                    <h2>6. Can I download this as a phone app?</h2>
                    <p>ABSOLUTELY!! (But only on iPhone)</p>
                    <p>To do so: click the share button in safari, then click "Add to Homescreen"</p>
                    <h2>How may I contact you?</h2>
                    <p>You may reach me via my GitHub: <a href="https://github.com/PilotKayer">PilotKayer</a></p>
                </div>
            </div>
            {/*<Error/>*/}
        </main>
    );
}

function Error() {
    const mobile = useMobile()

    const [remove, setRemove] = useState(false)

    return (
        <>{remove
            ? <></>
            : <div className={mobile ? m_styles.error : styles.error}>
                <p>WARNING:</p>
                <p>There is a known issue which prevents all post API functions to work on iOS browsers. A fix should be
                    available soon and this pop-up will be gone xD</p>
                <div onClick={() => setRemove(true)}>Close</div>
            </div>
        }
        </>
    )
}