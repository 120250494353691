import styles from "../modules/user.module.css";
import group_styles from "../modules/group.module.css";
import m_styles from "../modules/mobile.module.css";
import {Link, Navigate, useNavigate, useParams} from "react-router-dom";
import {deleteCookie, sendLogin, useUser} from "../auth/auth_user";
import {useEffect, useState} from "react";
import {Bet} from "../components/bets";
import {AddMember, EditGroup, MakeGroup, NewBet} from "../components/overlay";
import {useMobile, WEBSITE_NAME} from "../util/util";
import {NavMobile} from "../mobile_pages/mobile_nav";
import {AnimatePresence, motion} from "framer-motion";

export default function Group() {
    const mobile = useMobile()
    const navigate = useNavigate()
    const {signedUser, setSignedUser} = useUser()

    const [user_data, setData] = useState()
    const [group, setGroup] = useState(false)
    const [fail, setFail] = useState(false)
    const [error, setError] = useState("")
    const [isAuthenticating, setIsAuthenticating] = useState(false)

    function logOut() {
        setSignedUser("");
        deleteCookie('User');
        navigate("/")
    }

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': 'http://localhost:3000'
            },
            credentials: "include"
        };

        fetch(`https://znmfo6873b.execute-api.eu-west-3.amazonaws.com/default/data?type=getUserGroups&id=${signedUser}`, requestOptions)
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        setData(data)
                        })
                } else {
                    throw new Error('User does not exist or smt?');
                }
            }).catch(rejected => {
                setData(null)
                console.log(rejected);
            });
    }, [group]);

    if (signedUser === "" && !isAuthenticating) {
        sendLogin(
            navigate,
            "guest",
            "g_pass",
            setError,
            setFail,
            setIsAuthenticating
        ).then(data => setSignedUser("guest"))
    }

    if (!user_data) {
        return (
          <main className={mobile ? m_styles.main : styles.main}>
              {mobile
                ? <NavMobile/>
                : <div className={styles.nav}>
                    <div className={styles.nav_left}>
                        <Link to="/">{WEBSITE_NAME}</Link>
                        <Link to="/group">Groups</Link>
                        <Link to={`/user/${signedUser}`}>User</Link>
                    </div>
                    {signedUser
                        ? <div className={styles.nav_right} onClick={logOut}>Log Out</div>
                        : <div className={styles.nav_right}><Link to="/login">Login</Link>
                            <div>/</div>
                            <Link to="/register">Register</Link></div>
                    }
                </div>
            }
              <div className={mobile ? m_styles.content : styles.content}>
                  <div>
                      <h2>Loading</h2>
                      <p>Loading user: {signedUser}</p>
                  </div>
              </div>
          </main>
      );
    }

    const groups = Object.entries(user_data).map(key => <Link to={`/group/${key[0]}`}>- {key[1]}</Link>);

    return (
        <main className={mobile ? m_styles.main : styles.main}>
            {group
                ? <MakeGroup close_overlay={setGroup}/>
                : <div/>
            }
            {mobile
                ? <NavMobile/>
                : <div className={styles.nav}>
                    <div className={styles.nav_left}>
                        <Link to="/">{WEBSITE_NAME}</Link>
                        <Link to="/group">Groups</Link>
                        <Link to={`/user/${signedUser}`}>User</Link>
                    </div>
                    <div className={styles.nav_right} onClick={logOut}>Log Out</div>
                </div>
            }
            <div className={mobile ? m_styles.content_row : styles.content_row}>
                <div className={styles.content}>
                    <p>Your groups:</p>
                    {signedUser === "guest"
                        ? <div className={styles.new_group}>(login to make your own Group)</div>
                        : <div onClick={() => setGroup(true)} className={styles.new_group}>(Add new Group)</div>
                    }
                    {groups}
                </div>
            </div>
        </main>
    );
}

export function SpecificGroup() {
    const match = useParams()
    const mobile = useMobile()
    const {signedUser, setSignedUser} = useUser()
    const [group_data, setData] = useState()
    const [leaderboard, setLeaderboard] = useState(false)
    const [editGroup, setEditGroup] = useState(false)
    const [addMember, setAddMember] = useState(false)
    const [addBet, setAddBet] = useState(false)


    function logOut() {
        setSignedUser("");
        deleteCookie('User');
    }

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': 'http://localhost:3000'
            },
            credentials: "include"
        };

        fetch(`https://znmfo6873b.execute-api.eu-west-3.amazonaws.com/default/data?type=getGroup&id=${match.group}`, requestOptions)
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        setData(data)
                        })
                } else {
                    throw new Error('Group does not exist or smt?');
                }
            }).catch(rejected => {
                setData(null)
                console.log(rejected);
            });
    }, [editGroup, addBet]);

    if (signedUser === "") {
        return <Navigate to='/login'/>
    }

    if (!group_data) {
        return (
          <main className={styles.main}>
              <div className={styles.nav}>
                  <div className={styles.nav_left}>
                      <Link to="/">{WEBSITE_NAME}</Link>
                  </div>
              </div>
              <div className={styles.content}>
                  <div>
                      <h2>Loading</h2>
                      <p>Loading user: {match.group}</p>
                  </div>
              </div>
          </main>
      );
    }

    return (
        <main className={mobile ? m_styles.main : styles.main}>
            {editGroup
                ? <EditGroup group_id={group_data.group_id.N} close_overlay={setEditGroup}/>
                : <div/>
            }
            {addMember
                ? <AddMember group_id={group_data.group_id.N} close_overlay={setAddMember}/>
                : <div/>
            }
            {addBet
                ? <NewBet group_id={group_data.group_id.N} close_overlay={setAddBet}/>
                : <div/>
            }
            {mobile
                ? <NavMobile/>
                : <div className={styles.nav}>
                    <div className={styles.nav_left}>
                        <Link to="/">{WEBSITE_NAME}</Link>
                        <Link to="/group">Groups</Link>
                        <Link to={`/user/${signedUser}`}>User</Link>
                    </div>
                    {signedUser
                        ? <div className={styles.nav_right} onClick={logOut}>Log Out</div>
                        : <div className={styles.nav_right}><Link to="/login">Login</Link>
                            <div>/</div>
                            <Link to="/register">Register</Link></div>
                    }
                </div>
            }
            <div className={mobile ? m_styles.content_group : styles.content_group}>
                <div className={mobile ? m_styles.content_group : group_styles.content}>
                    <div className={mobile ? m_styles.group_name : group_styles.group_name}>
                        {group_data.group_name.S}
                    </div>
                    <div className={mobile ? m_styles.group_description : group_styles.group_description}>
                        {group_data.description.S}
                    </div>
                    {mobile
                        ? <div className={m_styles.options}>
                            {leaderboard
                                ? <div onClick={() => setLeaderboard(false)}>Bets</div>
                                : <div onClick={() => setLeaderboard(true)}>Members</div>
                            }
                            {leaderboard
                                ? <div onClick={() => setAddMember(true)}>Add Member</div>
                                : <div onClick={() => setAddBet(true)}>New Bet</div>
                            }
                        </div>
                        : <div className={group_styles.options}>
                            {leaderboard
                                ? <div onClick={() => setLeaderboard(false)}>Bets</div>
                                : <div onClick={() => setLeaderboard(true)}>Leaderboard</div>
                            }
                            <div onClick={() => setAddBet(true)}>New Bet</div>
                            <div onClick={() => setAddMember(true)}>Add Member</div>
                            <div onClick={() => setEditGroup(true)}>Edit Group</div>
                        </div>
                    }
                    {leaderboard
                        ? <Leaderboard group_id={group_data.group_id.N}/>
                        : <Bets group_id={group_data.group_id.N} refresh={addBet}/>
                    }
                </div>
            </div>
        </main>
    );
}

function Bets({group_id, refresh}) {
    const [bet_data, setData] = useState()
    const [all, setAll] = useState(false)
    const [bet_refresh, setBet_refresh] = useState(false)

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': 'http://localhost:3000'
            },
            credentials: "include"
        };

        fetch(`https://znmfo6873b.execute-api.eu-west-3.amazonaws.com/default/data?type=getBetsForGroup&id=${group_id}`, requestOptions)
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        setData(data)
                        })
                } else {
                    throw new Error('Group does not exist or smt?');
                }
            }).catch(rejected => {
                setData(null)
                console.log(rejected);
            });
    }, [group_id, refresh, bet_refresh]);

    if (!bet_data){
        return <div>Loading Bets...</div>
    }

    const bets = <AnimatePresence>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.2}}
            >
            {bet_data.map((value, idx) => {
                if (value.bet_id.N === "0") {
                    return <></>
                }

                return (
                    <motion.div
                        initial={{scale: 0, opacity: 0}}
                        animate={{scale: 1, opacity: 1}}
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20,
                            delay: 0.1 + idx / 10,
                        }}
                    >
                    <Bet
                        group_id={group_id}
                        data={value}
                        all={all}
                        refresh={bet_refresh}
                        setRefresh={setBet_refresh}
                    />
                    </motion.div>
                );
            })}
            </motion.div>
        </AnimatePresence>

    return <>
        {all
            ? <div className={group_styles.setting} onClick={() => setAll(false)}>Show Only New Bets</div>
            : <div className={group_styles.setting} onClick={() => setAll(true)}>Show All Bets</div>
        }
        {bets}
    </>
}

function Leaderboard({group_id}) {
    const [data, setData] = useState()

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': 'http://localhost:3000'
            },
            credentials: "include"
        };

        fetch(`https://znmfo6873b.execute-api.eu-west-3.amazonaws.com/default/data?type=getLeaderboard&id=${group_id}`, requestOptions)
            .then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        setData(data)
                        })
                } else {
                    throw new Error('Group does not exist or smt?');
                }
            }).catch(rejected => {
                setData(null)
                console.log(rejected);
            });
    }, []);

    if (!data) {
        return <div>Loading...</div>
    } else {
        const sorted = new Map([...Object.entries(data)].sort((a, b) => b[1] - a[1]));
        const object = Object.fromEntries(sorted.entries())
        const keys = Object.keys(object)
        const users = keys.flatMap(key => <li>{key}: {object[key]} pts</li>);

        return <div className={group_styles.leaderboard_container}><ol className={group_styles.leaderboard}>{users}</ol></div>
    }
}