import m_styles from "../modules/mobile.module.css";
import styles from "../modules/user.module.css";
import {NavMobile} from "../mobile_pages/mobile_nav";
import {Link, useNavigate} from "react-router-dom";
import {useMobile, WEBSITE_NAME} from "./util";
import {sendLogin, useUser} from "../auth/auth_user";
import {useState} from "react";

export function GuestGroups() {
    const mobile = useMobile()
    const navigate = useNavigate()
    const {setSignedUser} = useUser()

    const [fail, setFail] = useState(false)
    const [error, setError] = useState("")
    const [isAuthenticating, setIsAuthenticating] = useState(false)

    return (
          <main className={mobile ? m_styles.main : styles.main}>
              {mobile
                ? <NavMobile/>
                : <div className={styles.nav}>
                    <div className={styles.nav_left}>
                        <Link to="/">{WEBSITE_NAME}</Link>
                        <Link to="/group">Groups</Link>
                        <Link to={`/user`}>User</Link>
                    </div>
                    <div className={styles.nav_right}><Link to="/login">Login</Link>
                    <div>/</div>
                    <Link to="/register">Register</Link></div>
                </div>
            }
              <div className={mobile ? m_styles.content : styles.content}>
                  <div>
                      <h2>You are not logged in:</h2>
                      <p>Do you wish to: </p>
                      <Link to="/login">Login</Link>
                      <p>Check the website as a guest:</p>
                      <div onClick={() => {
                          sendLogin(
                            navigate,
                            "guest",
                            "g_pass",
                            setError,
                            setFail,
                            setIsAuthenticating
                        ).then(data => setSignedUser("guest"))
                      }}
                           className={styles.guest}
                      >Open as Guest</div>
                  </div>
              </div>
          </main>
      );
}