import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";
import {inviteUser, sendLogin, sendRegister, setCookie, useUser} from "../auth/auth_user";
import login_styles from "../modules/login.module.css";
import styles from "../modules/app.module.css";
import {useMobile, WEBSITE_NAME} from "./util";
import m_styles from "../modules/mobile.module.css";


export default function Invite() {
    const {signedUser} = useUser()
    const navigate = useNavigate()
    const match = useParams()
    const [login, setLogin] = useState(false)
    const [isAuthenticating, setIsAuthenticating] = useState(false)

    if (signedUser !== "") {
        const formData = {
            type: "inviteUser",
            validate: match.validate,
            group_id: match.group_id,
            username: signedUser
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(formData)
        };

        fetch('https://znmfo6873b.execute-api.eu-west-3.amazonaws.com/default/data', requestOptions)
            .then(response => {
                setIsAuthenticating(true)
                if (response.ok) {
                    return response.json().then(data => {
                        setIsAuthenticating(false)
                        navigate(`/group/${match.group_id}`)
                    });
                }
            }).catch(rejected => {
                setIsAuthenticating(false)
                console.log(rejected);
            });
    }

    return (<>
        {isAuthenticating
            ? <div className={login_styles.loading}>
                <div className={login_styles.form}>
                    <div className={login_styles.label}>
                        Loading...
                    </div>
                </div>
            </div>
            : <main className={styles.main}>
                <div className={styles.nav}>
                    <div className={styles.nav_left}>
                    <Link to="/">{WEBSITE_NAME}</Link>
                    </div>
                </div>
                {login
                    ? <Login group_id={match.group_id} validate={match.validate}/>
                    : <Register group_id={match.group_id} validate={match.validate}/>
                }
                <div className={styles.nav_right}>
                    {login
                        ? <div onClick={() => {
                            setLogin(false)
                        }}>Or Register</div>
                        : <div onClick={() => {
                            setLogin(true)
                        }}>Or Login</div>
                    }
                </div>
            </main>
    }</>)
}

function Login({group_id, validate}) {
    const mobile = useMobile()
    const {setSignedUser} = useUser()
    const navigate = useNavigate()

    const [fail, setFail] = useState(false)
    const [error, setError] = useState("")
    const [isAuthenticating, setIsAuthenticating] = useState(false)

    const [formData, setFormData] = useState({
        username: "",
        password: ""
    })

    const handleChange = (e) => {
        const {name, value, type, checked, options } = e.target;
        if (type === "checkbox") {
            const selectedOptions = Array.from(options).filter((option) => option.selected).map((option) => option.value);
            setFormData({ ...formData, [name]: selectedOptions });
        } else if (type === "radio" && checked) {
            setFormData({ ...formData, [name]: value });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = (e) => {
        sendLogin(
            navigate,
            formData.username,
            formData.password,
            setError,
            setFail,
            setIsAuthenticating
        ).then(() => {
            setSignedUser(formData.username)

            inviteUser(
                navigate,
                group_id,
                validate,
                formData.username
            ).then(() => console.log(
                `Successfully added ${formData.username} to group`
            ))
        });
    };

    return (
        <>
        {isAuthenticating
            ? <div className={login_styles.loading}>
                <div className={login_styles.form}>
                    <div className={login_styles.label}>
                        Loading...
                    </div>
                </div>
            </div>
            : <form className={mobile ? m_styles.login_form : login_styles.form} onSubmit={handleSubmit}>
                <label htmlFor="username" className={login_styles.label}>
                    Username:
                </label>
                <input
                    type="text"
                    id="username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    className={login_styles.input}/>
                <label htmlFor="password" className={login_styles.label}>
                    Password:
                </label>
                <input
                    type="password"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    className={login_styles.input}/>
                {fail
                    ? <div className={login_styles.error_message}>
                        {error !== ""
                            ? error
                            : "Login failed, please try again"
                        }
                    </div>
                    : <></>
                }
                <button type="submit" className={login_styles.button}>
                    Submit
                </button>
            </form>
    }
    </>)
}

function Register({group_id, validate}) {
    const mobile = useMobile()
    const {setSignedUser} = useUser()
    const navigate = useNavigate()

    const [fail, setFail] = useState(false)
    const [error, setError] = useState("")
    const [isAuthenticating, setIsAuthenticating] = useState(false)

    const [formData, setFormData] = useState({
        type: "makeUser",
        first_name: "",
        last_name: "",
        username: "",
        password: ""
    })

    const handleChange = (e) => {
        const {name, value, type, checked, options} = e.target;
        if (type === "checkbox") {
            const selectedOptions = Array.from(options).filter((option) => option.selected).map((option) => option.value);
            setFormData({ ...formData, [name]: selectedOptions });
        } else if (type === "radio" && checked) {
            setFormData({ ...formData, [name]: value });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = (e) => {
        sendRegister(
            navigate,
            formData,
            setError,
            setFail,
            setIsAuthenticating
        ).then(() => {
            sendLogin(
                navigate,
                formData.username,
                formData.password,
                setError,
                setFail,
                setIsAuthenticating
            ).then(() => {
                setSignedUser(formData.username)

                inviteUser(
                    navigate,
                    group_id,
                    validate,
                    formData.username
                ).then(() => console.log(
                    `Successfully added ${formData.username} to group`
                ))
            })
        })
    };

    return (
        <>
            {isAuthenticating
                ? <div className={login_styles.loading}>
                    <div className={login_styles.form}>
                        <div className={login_styles.label}>
                            Loading...
                        </div>
                    </div>
                </div>
                : <form className={mobile ? m_styles.login_form : login_styles.form} onSubmit={handleSubmit}>
                    <label htmlFor="first_name" className={login_styles.label}>
                        First Name:
                    </label>
                    <input
                        type="text"
                        id="first_name"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        className={login_styles.input}/>
                    <label htmlFor="last_name" className={login_styles.label}>
                        Last Name:
                    </label>
                    <input
                        type="text"
                        id="last_name"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        className={login_styles.input}/>
                    <label htmlFor="username" className={login_styles.label}>
                        Username:
                    </label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        className={login_styles.input}/>
                    <label htmlFor="password" className={login_styles.label}>
                        Password:
                    </label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        className={login_styles.input}/>
                    {fail
                        ? <div className={login_styles.error_message}>
                            {error !== ""
                                ? error
                                : "Register failed, please try again"
                            }
                        </div>
                        : <></>
                    }
                    <button type="submit" className={login_styles.button}>
                        Submit
                    </button>
                </form>
            }
        </>
    )
}