import {createContext, useContext, useEffect, useState} from "react";

export const WEBSITE_NAME = "Friendly-Bets.com"
export const API_ENDPOINT = "https://api.friendly-bets.com"

const MobileContext = createContext(undefined);

export function useMobile() {
  return useContext(MobileContext);
}

export function MobileProvider({ children }) {
  const feedback = useMobileStates();
  return (<MobileContext.Provider value={feedback}>{children}
  </MobileContext.Provider>);
}

export function useMobileStates() {
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return width <= 768
}