import React, {useEffect, useState} from "react";
import {useUser} from "../auth/auth_user";
import {API_ENDPOINT, useMobile} from "../util/util";
import {ChooseWinner, DeleteBet} from "./overlay";
import styles from "../modules/components.module.css";
import m_styles from "../modules/mobile.module.css";
import bet_styles from "../modules/bet.module.css";
import login_styles from "../modules/login.module.css";

export function Bet({group_id, data, all, refresh, setRefresh}) {
    const [side, setSide] = useState()
    const [selectSide, setSelectSide] = useState(false)
    const [winner, setWinner] = useState(false)
    const [deleteBet, setDelete] = useState(false)
    const {signedUser} = useUser()

    useEffect(() => {
        setRefresh(!refresh)
    }, [winner])

    useEffect(() => {
        if (data.bet_for.SS.includes(signedUser)) {
            setSide(true)
        } else if (data.bet_against.SS.includes(signedUser)) {
            setSide(false)
        }
    }, [])

    if (data.happened.BOOL && all) {
        if (typeof side == "boolean" && data.winner){
            if (side === data.winner.BOOL) {
                return (
                    <div className={bet_styles.bet_position}>
                        {winner
                        ? <ChooseWinner
                            group_id={group_id}
                            bet_id={data.bet_id.N}
                            description={data.description.S}
                            close_overlay={setWinner}/>
                        : <></>
                    }
                    {selectSide
                        ? <SelectSideOverlay
                            data={data}
                            setSide={setSide}
                            close_overlay={setSelectSide}/>
                        : <></>
                    }
                    {deleteBet
                        ? <DeleteBet
                            group_id={group_id}
                            bet_id={data.bet_id.N}
                            close_overlay={setDelete}/>
                        : <></>
                    }
                        <div className={bet_styles.bet_won}>
                            <h1 className={bet_styles.bet_banner}>WON</h1>
                            <h2 className={bet_styles.bet_description}>{data.description.S}</h2>
                            <div className={bet_styles.bet_stats_row}>
                                <p>
                                    FOR {data.bet_for.SS.length - 1} : AGAINST {data.bet_against.SS.length - 1}
                                </p>
                                <p
                                    className={bet_styles.bet_options}
                                    onClick={() => setDelete(true)}>
                                    Delete
                                </p>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        return (
            <div className={bet_styles.bet_position}>
                {winner
                    ? <ChooseWinner
                        group_id={group_id}
                        bet_id={data.bet_id.N}
                        description={data.description.S}
                        close_overlay={setWinner}/>
                    : <div/>
                }
                {deleteBet
                    ? <DeleteBet
                        group_id={group_id}
                        bet_id={data.bet_id.N}
                        close_overlay={setDelete}/>
                    : <div/>
                }
                <div className={bet_styles.bet_lost}>
                    <h1 className={bet_styles.bet_banner}>LOST</h1>
                    <h2 className={bet_styles.bet_description}>{data.description.S}</h2>
                    <div className={bet_styles.bet_stats_row}>
                        <p>
                            FOR {data.bet_for.SS.length - 1} : AGAINST {data.bet_against.SS.length - 1}
                        </p>
                        <p
                            className={bet_styles.bet_options}
                            onClick={() => setDelete(true)}>
                            Delete
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    if (typeof side == "boolean" && all) {
        if (side) {
            return (
                <div className={bet_styles.bet_position}>
                    {winner
                        ? <ChooseWinner
                            group_id={group_id}
                            bet_id={data.bet_id.N}
                            description={data.description.S}
                            close_overlay={setWinner}/>
                        : <></>
                    }
                    {selectSide
                        ? <SelectSideOverlay
                            data={data}
                            setSide={setSide}
                            close_overlay={setSelectSide}/>
                        : <></>
                    }
                    {deleteBet
                        ? <DeleteBet
                            group_id={group_id}
                            bet_id={data.bet_id.N}
                            close_overlay={setDelete}/>
                        : <></>
                    }
                    <div className={bet_styles.bet_for}>
                        <h1 className={bet_styles.bet_banner}>FOR</h1>
                        <h2 className={bet_styles.bet_description}>{data.description.S}</h2>
                        <div className={bet_styles.bet_stats_row}>
                            <p>
                                FOR {data.bet_for.SS.length - 1} : AGAINST {data.bet_against.SS.length - 1}
                            </p>
                            <p
                                className={bet_styles.bet_options}
                                onClick={() => setWinner(true)}>
                                End Bet
                            </p>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={bet_styles.bet_position}>
                    {winner
                        ? <ChooseWinner
                            group_id={group_id}
                            bet_id={data.bet_id.N}
                            description={data.description.S}
                            close_overlay={setWinner}/>
                        : <></>
                    }
                    {selectSide
                        ? <SelectSideOverlay
                            data={data}
                            setSide={setSide}
                            close_overlay={setSelectSide}/>
                        : <></>
                    }
                    {deleteBet
                        ? <DeleteBet
                            group_id={group_id}
                            bet_id={data.bet_id.N}
                            close_overlay={setDelete}/>
                        : <></>
                    }
                    <div className={bet_styles.bet_against}>
                        <h1 className={bet_styles.bet_banner}>AGAINST</h1>
                        <h2 className={bet_styles.bet_description}>{data.description.S}</h2>
                        <div className={bet_styles.bet_stats_row}>
                            <p>
                                FOR {data.bet_for.SS.length - 1} : AGAINST {data.bet_against.SS.length - 1}
                            </p>
                            <p
                                className={bet_styles.bet_options}
                                onClick={() => setWinner(true)}>
                                End Bet
                            </p>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <>
            {(typeof side != "boolean" && !data.happened.BOOL)
                ? <div className={bet_styles.bet_position}>
                    {winner
                        ? <ChooseWinner
                            group_id={group_id}
                            bet_id={data.bet_id.N}
                            description={data.description.S}
                            close_overlay={setWinner}/>
                        : <></>
                    }
                    {selectSide
                        ? <SelectSideOverlay
                            data={data}
                            setSide={setSide}
                            close_overlay={setSelectSide}/>
                        : <></>
                    }
                    {deleteBet
                        ? <DeleteBet
                            group_id={group_id}
                            bet_id={data.bet_id.N}
                            close_overlay={setDelete}/>
                        : <></>
                    }
                    <div className={bet_styles.bet_new}>
                        <h1 className={bet_styles.bet_banner}>FOR</h1>
                        <h2 className={bet_styles.bet_description}>{data.description.S}</h2>
                        <div className={bet_styles.bet_stats_row}>
                            <p>
                                FOR {data.bet_for.SS.length - 1} : AGAINST {data.bet_against.SS.length - 1}
                            </p>
                            <p
                                className={bet_styles.bet_options}
                                onClick={() => setSelectSide(true)}>
                                Vote Now
                            </p>
                        </div>
                    </div>
                </div>
                : <></>
            }
        </>
    )
}

function SelectSideOverlay({data, setSide, close_overlay}) {
    const mobile = useMobile()
    const {signedUser} = useUser()

    const [fail, setFail] = useState(false)

    const selectSide = (for_or_against) => {
        const form = {
            type: "addBetToUser",
            validate: signedUser,
            username: signedUser,
            bet_id: data.bet_id.N,
            for_or_against: for_or_against.toString()
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(form)
        };

        fetch(`${API_ENDPOINT}/data`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    response.json().then(data => {
                        setFail(true)
                        throw new Error(data)
                    })
                } else {
                    return response.json().then(() => {
                        setSide(for_or_against)
                        close_overlay(false)
                    })
                }
            }).catch(rejected => {
                console.log(rejected);
            });
    };

    return (
        <div className={styles.overlay}>
            <div className={mobile ? m_styles.overlay_backgroud : styles.overlay_backgroud}>
                <h1>Cast your vote:</h1>
                <p>{data.description.S}</p>
                {(fail && signedUser === "guest")
                    ? <div className={login_styles.error_message}>Guests cannot perform this action. <br/> Please create an account to continue.</div>
                    : <>
                        {fail
                            ? <div className={login_styles.error_message}>An error occurred. Please try again</div>
                            : <></>
                        }
                    </>
                }
                <div className={styles.buttons}>
                    <div className={mobile ? m_styles.button : styles.button} onClick={() => selectSide(true)}>
                        FOR
                    </div>
                    <div className={mobile ? m_styles.button : styles.button} onClick={() => close_overlay(false)}>
                        Cancel
                    </div>
                    <div className={mobile ? m_styles.button : styles.button} onClick={() => selectSide(false)}>
                        AGAINST
                    </div>
                </div>
            </div>
        </div>
    )
}