import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "../modules/components.module.css";
import login_styles from "../modules/login.module.css";
import m_styles from "../modules/mobile.module.css";
import {useUser} from "../auth/auth_user";
import {API_ENDPOINT, useMobile} from "../util/util";
import {useNavigate} from "react-router-dom";

export function MakeGroup({close_overlay}) {
    const mobile = useMobile()
    const [fail, setFail] = useState(false)
    const {signedUser} = useUser()
    const [formData, setFormData] = useState({
        type: "makeGroup",
        validate: signedUser,
        group_name: "",
        description: ""
    });

    const handleChange = (e) => {
        const { name, value, type, checked, options } = e.target;
        if (type === "checkbox") {
            const selectedOptions = Array.from(options).filter((option) => option.selected).map((option) => option.value);
            setFormData({ ...formData, [name]: selectedOptions });
        } else if (type === "radio" && checked) {
            setFormData({ ...formData, [name]: value });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(formData)
        };

        fetch(`${API_ENDPOINT}/data`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    setFail(true)
                } else {
                    setFail(false)
                    return response.json()
                }
            })
            .then(data => {
                if (!data) return;
                close_overlay(false)
            }).catch(rejected => {
                setFail(true)
                console.log(rejected);
            });
    };

    return(
        <div className={styles.overlay}>
            <div className={mobile ? m_styles.overlay_backgroud : styles.overlay_backgroud}>
                <h1>Create New Group:</h1>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <label htmlFor="group_name" className={mobile ? m_styles.label : styles.label}>
                        Group Name:
                    </label>
                    <input
                        type="text"
                        id="group_name"
                        name="group_name"
                        value={formData.group_name}
                        onChange={handleChange}
                        className={mobile ? m_styles.input : styles.input}/>
                    <label htmlFor="description" className={mobile ? m_styles.label : styles.label}>
                        Group Description:
                    </label>
                    <textarea
                        rows="5"
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className={mobile ? m_styles.input : styles.input}/>
                    {(fail && signedUser === "guest")
                        ? <div className={login_styles.error_message}>Guests cannot perform this action. <br/> Please create an account to continue.</div>
                        : <>
                            {fail
                                ? <div className={login_styles.error_message}>An error occurred. Please try again</div>
                                : <></>
                            }
                        </>
                    }
                    <div className={styles.buttons}>
                        <button type="submit" className={mobile ? m_styles.button : styles.button}>
                            Submit
                        </button>
                        <button onClick={() => close_overlay(false)} className={mobile ? m_styles.button : styles.button}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export function EditMember({close_overlay}) {
    const mobile = useMobile()
    const navigate = useNavigate()

    const [fail, setFail] = useState(false)
    const {signedUser, setSignedUser} = useUser()
    const [formData, setFormData] = useState({
        type: "changeName",
        validate: signedUser,
        first_name: "",
        last_name: ""
    });

    const handleDelete = () => {
        const form = {
            type: "deleteUser",
            validate: signedUser
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(form)
        };

        fetch(`${API_ENDPOINT}/data`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    setFail(true)
                } else {
                    setFail(false)
                    return response.json().then(data => {
                        setSignedUser("")
                        close_overlay(false)
                        navigate('/')
                    })
                }
            })
            .catch(rejected => {
                setFail(true)
                console.log(rejected);
            });
    }

    const handleChange = (e) => {
        const { name, value, type, checked, options } = e.target;
        if (type === "checkbox") {
            const selectedOptions = Array.from(options).filter((option) => option.selected).map((option) => option.value);
            setFormData({ ...formData, [name]: selectedOptions });
        } else if (type === "radio" && checked) {
            setFormData({ ...formData, [name]: value });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        const requestOptions = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(formData)
        };

        fetch('https://znmfo6873b.execute-api.eu-west-3.amazonaws.com/default/data', requestOptions)
            .then(response => {
                if (!response.ok) {
                    setFail(true)
                } else {
                    setFail(false)
                    return response.json()
                }
            })
            .then(data => {
                if (!data) return;
                close_overlay(false)
            }).catch(rejected => {
                setFail(true)
                console.log(rejected);
            });
    };

    return(
        <div className={styles.overlay}>
            <div className={mobile ? m_styles.overlay_backgroud : styles.overlay_backgroud}>
                <h1>Change user's name / last name:</h1>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <label htmlFor="first_name" className={mobile ? m_styles.label : styles.label}>
                        Change First Name:
                    </label>
                    <input
                        type="text"
                        id="first_name"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        className={mobile ? m_styles.input : styles.input}/>
                    <label htmlFor="last_name" className={mobile ? m_styles.label : styles.label}>
                        Change Last Name:
                    </label>
                    <input
                        type="text"
                        id="last_name"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        className={mobile ? m_styles.input : styles.input}/>
                    {(fail && signedUser === "guest")
                        ? <div className={login_styles.error_message}>Guests cannot perform this action. <br/> Please create an account to continue.</div>
                        : <>
                            {fail
                                ? <div className={login_styles.error_message}>An error occurred. Please try again</div>
                                : <></>
                            }
                        </>
                    }
                    <div className={styles.buttons}>
                        <button type="submit" className={mobile ? m_styles.button : styles.button}>
                            Submit
                        </button>
                        <button onClick={() => close_overlay(false)}
                                className={mobile ? m_styles.button : styles.button}>
                            Cancel
                        </button>
                    </div>
                    <div
                        className={mobile ? m_styles.del_button : styles.del_button}
                        onClick={() => handleDelete()}>
                        Permanently delete account
                    </div>
                </form>
            </div>
        </div>
    )
}

export function EditGroup({group_id, close_overlay}) {
    const mobile = useMobile()
    const [fail, setFail] = useState(false)
    const {signedUser} = useUser()
    const [formData, setFormData] = useState({
        type: "editGroup",
        validate: signedUser,
        group_id: group_id,
        group_name: "",
        description: ""
    });

    const handleChange = (e) => {
        const { name, value, type, checked, options } = e.target;
        if (type === "checkbox") {
            const selectedOptions = Array.from(options).filter((option) => option.selected).map((option) => option.value);
            setFormData({ ...formData, [name]: selectedOptions });
        } else if (type === "radio" && checked) {
            setFormData({ ...formData, [name]: value });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(formData)
        };

        fetch(`${API_ENDPOINT}/data`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    setFail(true)
                } else {
                    setFail(false)
                    return response.json()
                }
            })
            .then(data => {
                if (!data) return;
                close_overlay(false)
            }).catch(rejected => {
                setFail(true)
                console.log(rejected);
            });
    };

    return(
        <div className={styles.overlay}>
            <div className={mobile ? m_styles.overlay_backgroud : styles.overlay_backgroud}>
                <h1>Edit Group:</h1>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <label htmlFor="group_name" className={mobile ? m_styles.label : styles.label}>
                        Change Group Name:
                    </label>
                    <input
                        type="text"
                        id="group_name"
                        name="group_name"
                        value={formData.group_name}
                        onChange={handleChange}
                        className={mobile ? m_styles.input : styles.input}/>
                    <label htmlFor="description" className={mobile ? m_styles.label : styles.label}>
                        Change Group Description:
                    </label>
                    <textarea
                        rows="5"
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className={mobile ? m_styles.input : styles.input}/>
                    {(fail && signedUser === "guest")
                        ? <div className={login_styles.error_message}>Guests cannot perform this action. <br/> Please create an account to continue.</div>
                        : <>
                            {fail
                                ? <div className={login_styles.error_message}>An error occurred. Please try again</div>
                                : <></>
                            }
                        </>
                    }
                    <div className={styles.buttons}>
                        <button type="submit" className={mobile ? m_styles.button : styles.button}>
                            Submit
                        </button>
                        <button onClick={() => close_overlay(false)} className={mobile ? m_styles.button : styles.button}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export function AddMember({group_id, close_overlay}) {
    const mobile = useMobile()
    const [fail, setFail] = useState(false)
    const {signedUser} = useUser()
    const [formData, setFormData] = useState({
        type: "addMember",
        validate: signedUser,
        group_id: group_id,
        username: ""
    });

    const handleChange = (e) => {
        const { name, value, type, checked, options } = e.target;
        if (type === "checkbox") {
            const selectedOptions = Array.from(options).filter((option) => option.selected).map((option) => option.value);
            setFormData({ ...formData, [name]: selectedOptions });
        } else if (type === "radio" && checked) {
            setFormData({ ...formData, [name]: value });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(formData)
        };

        fetch(`${API_ENDPOINT}/data`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    setFail(true)
                } else {
                    setFail(false)
                    return response.json()
                }
            })
            .then(data => {
                if (!data) return;
                close_overlay(false)
            }).catch(rejected => {
                setFail(true)
                console.log(rejected);
            });
    };

    const invite_link = `https://www.friendly-bets.com/#/invite/${signedUser.replace('/ /g', '%20')}/${group_id}`
    return(
        <div className={styles.overlay}>
            <div className={mobile ? m_styles.overlay_backgroud : styles.overlay_backgroud}>
                <h1>Add member:</h1>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <label htmlFor="username" className={mobile ? m_styles.label : styles.label}>
                        Add Member to Group (username):
                    </label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        className={mobile ? m_styles.input : styles.input}/>
                    <label htmlFor="username" className={mobile ? m_styles.label : styles.label}>
                        Or Copy and send them this link:
                    </label>
                    <div
                        className={mobile ? m_styles.share_link : styles.share_link}
                    onClick={() => {
                        navigator.clipboard.writeText(invite_link);
                        close_overlay(false);
                    }}>
                        Click to Copy Link
                    </div>
                    {(fail && signedUser === "guest")
                        ? <div className={login_styles.error_message}>Guests cannot perform this action. <br/> Please create an account to continue.</div>
                        : <>
                            {fail
                                ? <div className={login_styles.error_message}>An error occurred. Please try again</div>
                                : <></>
                            }
                        </>
                    }
                    <div className={styles.buttons}>
                        <button type="submit" className={mobile ? m_styles.button : styles.button}>
                            Submit
                        </button>
                        <button onClick={() => close_overlay(false)} className={mobile ? m_styles.button : styles.button}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export function NewBet({group_id, close_overlay}) {
    const mobile = useMobile()
    const [fail, setFail] = useState(false)
    const [date, setDate] = useState(new Date());
    const {signedUser} = useUser()
    const [formData, setFormData] = useState({
        type: "makeBet",
        validate: signedUser,
        group_id: group_id,
        description: "",
        positive_bet: "false",
        end_date: [
            (date.getDate().toString().length < 2 ? "0" + date.getDate().toString() : date.getDate().toString()),
            ((date.getMonth()+1).toString().length < 2 ? "0" + (date.getMonth()+1).toString() : (date.getMonth()+1).toString()),
            date.getFullYear()
        ].join('-'),
        for_or_against: "false"
    });

    useEffect(() => {
        const day = (date.getDate().toString().length < 2 ? "0" + date.getDate().toString() : date.getDate().toString())
        const month = ((date.getMonth()+1).toString().length < 2 ? "0" + (date.getMonth()+1).toString() : (date.getMonth()+1).toString())
        setFormData({ ...formData, end_date: [day, month, date.getFullYear()].join('-')});
    }, [date]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === "checkbox") {
            setFormData({ ...formData, [name]: checked.toString() });
        } else if (type === "radio" && checked) {
            setFormData({ ...formData, [name]: value });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        const requestOptions = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(formData)
        };

        fetch(`${API_ENDPOINT}/data`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    setFail(true)
                } else {
                    setFail(false)
                    return response.json().then(data => {
                        if (!data) return;
                        close_overlay(false)
                    })
                }
            }).catch(rejected => {
                setFail(true)
                console.log(rejected);
            });
    };

    return(
        <div className={styles.overlay}>
            <div className={mobile ? m_styles.overlay_backgroud : styles.overlay_backgroud}>
                <h1>Create New Bet:</h1>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <label className={mobile ? m_styles.label : styles.label}>Pick an end date for this new bet:</label>
                    <DatePicker selected={date} onChange={(date) => setDate(date)}/>
                    <label htmlFor="description" className={mobile ? m_styles.label : styles.label}>
                        Bet Description:
                    </label>
                    <textarea
                        rows="5"
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className={mobile ? m_styles.input : styles.input}/>
                    <div className={styles.inline}>
                        <label htmlFor="positive_bet" className={mobile ? m_styles.label : styles.label}>
                            Would you say this is a POSITIVE bet?
                        </label>
                        <input
                            type="checkbox"
                            id="positive_bet"
                            name="positive_bet"
                            value={formData.positive_bet}
                            onChange={handleChange}
                            className={styles.radio}/>
                    </div>
                    <div className={styles.inline}>
                        <label htmlFor="for_or_against" className={mobile ? m_styles.label : styles.label}>
                            Would you like to bet FOR?
                        </label>
                        <input
                            type="checkbox"
                            id="for_or_against"
                            name="for_or_against"
                            value={formData.for_or_against}
                            onChange={handleChange}
                            className={styles.radio}
                        />
                    </div>
                    {(fail && signedUser === "guest")
                        ? <div className={login_styles.error_message}>Guests cannot perform this action. <br/> Please create an account to continue.</div>
                        : <>
                            {fail
                                ? <div className={login_styles.error_message}>An error occurred. Please try again</div>
                                : <></>
                            }
                        </>
                    }
                    <div className={styles.buttons}>
                        <button type="submit" className={mobile ? m_styles.button : styles.button}>
                            Submit
                        </button>
                        <button onClick={() => close_overlay(false)} className={mobile ? m_styles.button : styles.button}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export function ChooseWinner({group_id, bet_id, description, close_overlay}) {
    const mobile = useMobile()
    const [fail, setFail] = useState(false)
    const {signedUser} = useUser()

    const selectWinner = (for_or_against) => {
        const form = {
            type: "selectWinner",
            validate: signedUser,
            group_id: group_id,
            bet_id: bet_id,
            for_or_against: for_or_against.toString()
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(form)
        };

        fetch(`${API_ENDPOINT}/data`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    setFail(true)
                } else {
                    setFail(false)
                    return response.json().then(data => {
                        if (!data) return;
                        close_overlay(false)
                    })
                }
            }).catch(rejected => {
                setFail(true)
                console.log(rejected);
            });
    }

    return(
        <div className={styles.overlay}>
            <div className={mobile ? m_styles.overlay_backgroud : styles.overlay_backgroud}>
                <h1>Select Winners:</h1>
                <p>{description}</p>
                {(fail && signedUser === "guest")
                    ? <div className={login_styles.error_message}>Guests cannot perform this action. <br/> Please create an account to continue.</div>
                    : <>
                        {fail
                            ? <div className={login_styles.error_message}>An error occurred. Please try again</div>
                            : <></>
                        }
                    </>
                }
                <div className={styles.buttons}>
                    <div className={mobile ? m_styles.button : styles.button} onClick={() => selectWinner(true)}>
                        FOR
                    </div>
                    <div className={mobile ? m_styles.button : styles.button} onClick={() => close_overlay(false)}>
                        Cancel
                    </div>
                    <div className={mobile ? m_styles.button : styles.button} onClick={() => selectWinner(false)}>
                        AGAINST
                    </div>
                </div>
            </div>
        </div>
    )
}

export function DeleteBet({group_id, bet_id, close_overlay}) {
    const mobile = useMobile()
    const [fail, setFail] = useState(false)
    const {signedUser} = useUser()

    const sendDelete = () => {
        const form = {
            type: "deleteBet",
            validate: signedUser,
            group_id: group_id,
            bet_id: bet_id
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify(form)
        };

        fetch(`${API_ENDPOINT}/data`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    setFail(true)
                } else {
                    setFail(false)
                    return response.json().then(data => {
                        if (!data) return;
                        close_overlay(false)
                        window.location.reload()
                    })
                }
            }).catch(rejected => {
                setFail(true)
                console.log(rejected);
            });
    }

    return(
        <div className={styles.overlay}>
            <div className={mobile ? m_styles.overlay_backgroud : styles.overlay_backgroud}>
                <h1>ARE YOU SURE YOU WISH TO DELETE THIS BET?</h1>
                <p>By deleting this bet, all points associated with it will be deleted!!! THIS ACTION CANNOT BE REVERTED</p>
                {(fail && signedUser === "guest")
                    ? <div className={login_styles.error_message}>Guests cannot perform this action. <br/> Please create an account to continue.</div>
                    : <>
                        {fail
                            ? <div className={login_styles.error_message}>An error occurred. Please try again</div>
                            : <></>
                        }
                    </>
                }
                <div className={styles.buttons}>
                    <div className={mobile ? m_styles.button : styles.button} onClick={sendDelete}>
                        DELETE
                    </div>
                    <div className={mobile ? m_styles.button : styles.button} onClick={() => close_overlay(false)}>
                        Cancel
                    </div>
                </div>
            </div>
        </div>
    )
}