import {createContext, useContext, useState} from 'react';
import Cookies from 'universal-cookie';
import {useNavigate} from "react-router-dom";
import {API_ENDPOINT} from "../util/util";

const cookies = new Cookies(null, { path: '/' });
const UserFeedbackContext = createContext(undefined);

export function useUser() {
  return useContext(UserFeedbackContext);
}

export function UserAuthProvider({ children }) {
  const feedback = useUserStates();
  return (<UserFeedbackContext.Provider value={feedback}>{children}
  </UserFeedbackContext.Provider>);
}

export function useUserStates() {
    const user = getCookie('User')
    const [signedUser, setSignedUser] = useState((user || ""))
    const [isAuthenticating, setIsAuthenticating] = useState(false)

    return {signedUser, isAuthenticating, setSignedUser, setIsAuthenticating}
}

export function setCookie(name, value) {
    cookies.set(name, (value || ""), {maxAge: 86395});
}

export function getCookie(name) {
    return cookies.get(name)
}

export function deleteCookie(name) {
    cookies.remove(name)
}

export async function sendLogin(navigate, username, password, setError, setFail, setIsAuthenticating) {
    if (username === "" || password === ""){
        setFail(true)
        setError("Username and Password cannot be empty")
        return
    } else if (username.search(/ /) > 0 || password.search(/ /) > 0) {
        setFail(true)
        setError("Username and Password cannot contain spaces")
        return
    }

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': username + ':' + password
        },
        credentials: "include"
    };

    setIsAuthenticating(true)

    const response = await fetch(`${API_ENDPOINT}/token`, requestOptions)
    if (!response.ok) {
        setFail(true)
        const error = await response.json()

        setError(error.message)
        setIsAuthenticating(false)
    } else {
        setFail(false)
        const data = await response.json()

        if (!data) return;
        setIsAuthenticating(false)
        setCookie('User', username)
        navigate(`/group`)
    }
}

export async function sendRegister(navigate, formData, setError, setFail, setIsAuthenticating) {
    if (formData.username === "" || formData.password === ""){
        setFail(true)
        setError("Username and Password cannot be empty")
        return
    } else if (formData.username.search(/ /) > 0 || formData.password.search(/ /) > 0) {
        setFail(true)
        setError("Username and Password cannot contain spaces")
        return
    }

    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
    };

    setIsAuthenticating(true)

    const response =  await fetch(`${API_ENDPOINT}/data`, requestOptions)
    if (!response.ok) {
        setFail(true)
        const error = await response.json()

        setError(error.message)
        setIsAuthenticating(false)
    }
}

export async function inviteUser(navigate, group_id, validate, username) {
    const data = {
        type: "inviteUser",
        validate: validate,
        group_id: group_id,
        username: username
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify(data)
    };

    const response = await fetch(`${API_ENDPOINT}/data`, requestOptions)

    if (!response.ok) {
        return response.json().then(error => {
            console.log(error.message)
        })
    } else {
        return response.json().then(data => {
            if (!data) return;
            navigate(`/group/${group_id}`)
        })
    }
}